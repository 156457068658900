import React from "react"

import styled, { createGlobalStyle } from "styled-components"

const FullIframe = styled.iframe`
  width: 100vw;
  height: 100vh;
  border: none;
  margin: 0;
`

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    overflow: hidden;
  }
`

const MavericksSupport = () => (
  <>
    <GlobalStyle />
    <FullIframe
      src="https://tixsee.formstack.com/forms/mavs_mobile_app_feedback"
      title="Online Form"
    />
  </>
)

export default MavericksSupport
